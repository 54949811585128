<template>
  <common-form
    ref="form"
    url="dept"
    :edit="edit"
    :items="items"
    @success="$emit('success')"></common-form>
</template>
<script>

  export default {
    data() {
      return {
        edit:false,
        treeData: [],
      }
    },
    computed:{
      items() {
        return [
          {
            type: "input",
            name: "部门名称",
            key: "deptName",
            required: true,
          },
          {
            type: "number",
            name: "排序",
            key: "orderNum",
          },
          {
            type: "tree-select",
            name: "上级部门",
            key: "parentId",
            treeData: this.treeData
          },
        ]
      }
    },
    methods: {
      show(model = {}) {
        this.edit = model.id>0
        let data = {}
        if(model.id>0){
          data = {
            deptId:model.id,
            deptName:model.text,
            parentId:model.parentId,
            orderNum:model.order
          }
        }

        this.$get('dept').then((r) => {
          this.treeData = r.data.rows.children
        })

        this.$refs.form.show(data)

      },

    }
  }
</script>
